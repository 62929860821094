/**
 *Created by 刘冬 on 2019/1/21
 */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import App from './../App';
import apk from './../page/apk';

const Root = () => (
    <div>
        <Switch>
            <Route
                path="/"
                render={props => (
                    <App>
                        <Switch>
                            <Route path="/" exact component={apk} />
                            <Route path="/apk" component={apk} />
                            <Route path="/apk/:type" component={apk} />
                        </Switch>
                    </App>
                )}
            />
        </Switch>
    </div>
);

export default Root;
