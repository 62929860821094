import {Component} from "react";
import {Button, NavBar} from "antd-mobile";
import bg from "../img/bg.png";
import React from "react";
import  '../App.css';

/**
 *Created by 刘冬 on 2019/1/18
 */
class apk extends Component {

	constructor(props)
	{
		super(props);
		this.state={
			type:'',
			isShow:'',
			downLoadUrl:'',
			buttonName:'下载',
			logo:'https://image.jhcampus.cn/coupons/icon/logo.png',
			userAgent:''
		}
	}







	componentWillMount()
	{
		//获取当前页面地址
		// let type = this.getQueryString('type');
		let userAgent = "";
		let u = navigator.userAgent;
		if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1)
		{
			userAgent = "Android";
		}
		else if (u.indexOf('iPhone') > -1)
		{
			userAgent = "IOS";
		}
		else if (u.indexOf('Windows Phone') > -1)
		{
			alert("暂不支持该系统类型手机");
		}
		this.setState({userAgent:userAgent},()=>{
			if(userAgent == "Android")
			{
				this.setState({
					downLoadUrl:'https://image.jhcampus.cn/coupons/apk/checkBill.apk',
				})
			}
			else if(userAgent == "IOS")
			{
				alert("抱歉，暂不支持苹果端下载");
				// this.setState({
				// downLoadUrl:'https://itunes.apple.com/cn/app/%E7%B2%AE%E5%88%B0%E5%95%A6%E6%9C%8D%E5%8A%A1%E7%AB%AF%E6%A0%A1%E5%9B%AD%E7%89%88/id1465212043?mt=8',
				// })
			}
		});
	}



	downLoad=()=>
	{
		if(this.state.userAgent == "Android")
		{
			this.isWeiXin();
		}
		else
		{
			alert("抱歉，该手机机型暂不支持下载");
		}
	}






	getQueryString(name)
	{
		var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
		var r = window.location.search.substr(1).match(reg);
		if (r != null)
		{
			return unescape(r[2]);
		}
		return null;
	}


	//判断是否是微信浏览器的函数
	isWeiXin=()=>
	{
		//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
		var ua = window.navigator.userAgent.toLowerCase();
		//通过正则表达式匹配ua中是否含有MicroMessenger字符串
		if(ua.match(/MicroMessenger/i) == 'micromessenger')
		{
			this.setState({
				isShow:true
			})
		}
		else
		{
			this.setState({
				isShow:false
			});
			window.location.href = this.state.downLoadUrl;
		}
	}





	render() {
		let isShow = this.state.isShow;
		return (
			<div className="App">
				<NavBar
					mode="light"
				>下载中心</NavBar>
				<div className="App-header">
					<img src={this.state.logo} className="App-logo" alt="logo" />
					<Button type="primary" inline size="small" onClick={this.downLoad}>{this.state.buttonName}</Button>
					<span className="apk-introduction">APP介绍：“耀用券”商家查账及支付播报提示。</span>
				</div>
				{
					isShow?
						<div className="bgimg">
							<img src={bg} alt="bg" />
						</div>
						:
						<div></div>
				}
			</div>
		);
	}
}

export default apk;
