/**
 *Created by 刘冬 on 2019/1/21
 */
import React, { Component } from 'react';
import 'antd-mobile/dist/antd-mobile.css'

class App extends Component {
    render() {
        return <div>{this.props.children}</div>;
    }
}
export default App;
